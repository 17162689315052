import styles from "./checkbox.module.scss";

export default function CamelotCheckbox(props: {
  action?: () => unknown;
}) {
  return (
    <div className={styles.main}>
      <input style={{minWidth:'20px'}} type="checkbox" onChange={() => props.action && props.action()} />
      <label>
        Ho preso visione dell'
        <a style={{color: "#0069ca"}} target="_blank" href="https://camelotsrlsb.s3.eu-west-1.amazonaws.com/informativa+votazione+bilancio+partecipato+comune+vallelaghi+.pdf">informativa sul trattamento dei dati personali</a>,
        fornitami dal Titolare ai sensi dell’art. 13 del GDPR
      </label>
    </div>
  );
}
