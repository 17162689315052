import styles from "./response.module.scss";
import successIcon from "../../libs/assets/success.svg";
import errorIcon from "../../libs/assets/errorIcon.svg";
import CamelotButton from "../CamelotButton";
import { Response } from "../../models/step_enum";

export default function ResponseView(props: {
  response: Response;
  errorMessage?: string;
  toForm?: () => unknown;
  toOtpView?: () => void;
  resendOtp?: () => void;
  nome?: string;
  email?: string;
}) {
  return (
    <>
      {props.response === Response.success ? (
        <div className={styles.main}>
          <p style={{ color: "#0069CA", margin: "0", fontWeight: 800 }}>
            GRAZIE
          </p>
          <p style={{ color: "#0069CA", margin: "0" }}>
            PROCEDURA DI IDENTIFICAZIONE CONCLUSA
          </p>
          <img src={successIcon} alt="success" />
          {/* <p style={{ color: "#8F8F8F", margin: "0", textAlign: "center" }}>
            Controlla la tua casella di posta. Troverai la conferma di avvenuta registrazione.
          </p>
          <p style={{ color: "#8F8F8F", margin: "0" }}>
            Ti ricordiamo di controllare anche nella cartella "SPAM".
          </p>          */}
           <p style={{ color: "#8F8F8F", margin: "0" }}>
            A breve riceverai il link di voto tramite SMS.
          </p>
        </div>
      ) : (
        <div className={styles.main}>
          <p style={{ color: "#0069CA", margin: "0", fontWeight: 800 }}>
            CI DISPIACE!
          </p>
          <p
            style={{
              color: "#0069CA",
              margin: "0",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {props.errorMessage != null ? props.errorMessage : ""}
          </p>
          <img src={errorIcon} alt="success" />
          <p style={{ color: "#8F8F8F", margin: "0" }}>
            Prova a reinserire i tuoi dati. Nel caso in cui il problema persista
            ti chiediamo di contattarci a
            <strong style={{ color: "#818181", margin: "0" }}>
              {" "}
              support@camelot.vote
            </strong>
          </p>
          

          {props.response === Response.responseFailedOtp 
          &&
          props.errorMessage === "Il codice OTP inserito è errato" ? (
            <div className={styles.buttons}>
              <CamelotButton
                text="TORNA AL FORM"
                ghost
                action={async () => props.toForm && props.toForm()}
              />
              <CamelotButton
                text="RIPROVA"
                action={() => props.toOtpView && props.toOtpView()}
              />
            </div>
          ) : props.response === Response.responseFailedOtp &&
            props.errorMessage === "Il tuo codice OTP è scaduto" ? (
            <div className={styles.buttons}>
              <CamelotButton
                text="TORNA AL FORM"
                ghost
                action={async () => props.toForm && props.toForm()}
              />
              <CamelotButton
                text="REINVIA OTP"
                action={() => {
                  props.resendOtp && props.resendOtp();
                }}
              />
            </div>
          ) : (
            <div className={styles.buttons}>
              <CamelotButton
                text="TORNA AL FORM"
                action={async () => props.toForm && props.toForm()}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
